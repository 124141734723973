import React from "react"
import Layout from '../../components/templates/Layout';
import {graphql, StaticQuery} from "gatsby";
import Header from "../../components/atoms/header/header";
import PortfolioListWithData from "../../components/organisms/PortfolioList/PortfolioList.query";

const PortfolioPage = () => (
    <StaticQuery query={graphql`
query portfolioPageEN {
    drupal {
        page:entityById(id: "43", langcode: EN, entityType: NODE) {
            ... on Drupal_NodePage {
                nid
                title
                body 
                fieldOpening 
                internalId: nid
                path {
                    alias
                }
                url {
                    ... on Drupal_EntityUrl {
                        metatags {
                            tag
                            attributes {
                                key
                                value
                            }
                        }
                    }
                }                
            }
        }
    }
}    `}
                 render={data => (
                     <Layout
                         lang={'en'}
                         class={'portfolio'}
                         activeMenu={'portfolio'}
                         activeNid={43}
                         metatags={data.drupal.page.url.metatags}
                         title={data.drupal.page.title}
                     >
                         <Header
                             children={data.drupal.page.body}
                             intro={data.drupal.page.fieldOpening}
                             page_title={data.drupal.page.title} />

                         <PortfolioListWithData />
                     </Layout>
                 )}
    />
);
export default PortfolioPage;
